import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ShowAuthedDirective } from './directives/show-authed.directive';
import { PreloadImageDirective } from './directives/preload-image.directive';
import { ShowForRoleDirective } from './directives/show-for-role.directive';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { TimeFormatPipe } from './pipes/time-format.pipe';
import { DateTimeFormatPipe } from './pipes/datetime-format.pipe';
import { NumberToEuroPipe } from './pipes/number-to-euro.pipe';
import { ReplacePipe } from './pipes/replace.pipe';
import { StringPipe } from './pipes/string.pipe';
import { NewsUrlPipe } from './pipes/news-url.pipe';
import { UrlTrailerPipe } from './pipes/url-trailer.pipe';
import { DictionaryValuePipe } from './pipes/dictionary-value.pipe';
import { DictionaryIdPipe } from './pipes/dictionaryId.pipe';
import { NumberToPercentPipe } from './pipes/number-to-percent.pipe';
import { NumberToLocaleFormatPipe } from './pipes/number-to-locale-format.pipe';
import { HttpRequestInterceptor } from './interceptors/http-request.interceptor';
import { HttpTokenInterceptor } from './interceptors/http-token.interceptor';
import { IssetPipe } from './pipes/isset.pipe';

@NgModule({
  declarations: [
    DateTimeFormatPipe,
    DateFormatPipe,
    TimeFormatPipe,
    NumberToEuroPipe,
    ReplacePipe,
    ShowForRoleDirective,
    PreloadImageDirective,
    ShowAuthedDirective,
    StringPipe,
    NewsUrlPipe,
    UrlTrailerPipe,
    DictionaryValuePipe,
    DictionaryIdPipe,
    NumberToPercentPipe,
    NumberToLocaleFormatPipe,
    IssetPipe
  ],
  imports: [
    CommonModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptor,
      multi: true
    }
  ],
  exports: [
    DateTimeFormatPipe,
    DateFormatPipe,
    NumberToEuroPipe,
    TimeFormatPipe,
    ReplacePipe,
    ShowForRoleDirective,
    PreloadImageDirective,
    ShowAuthedDirective,
    StringPipe,
    NewsUrlPipe,
    UrlTrailerPipe,
    DictionaryIdPipe,
    DictionaryValuePipe,
    NumberToPercentPipe,
    NumberToLocaleFormatPipe,
    IssetPipe
  ]
})
export class CoreModule { }
