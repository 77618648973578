export enum ResolverKeys {
  GENRES = 'genres',
  DISCRIPTORS = 'discriptors',
  AGE_RATINGS = 'ageRatings',
  CAST_TYPES = 'castTypes',
  MOVIE_TYPES = 'movieTypes',
  VERSION_TYPES = 'versionTypes',
  COUNTRIES_MOVIE = 'countriesMovie',
  DISTRIBUTORS = 'distributors',
  AWS_DASHBOARD_URL = 'awsDashboardUrl',
  COUNTRIES = 'countries',
  USER = 'user',
  LANGUAGES_SNIPPETS = 'languagesSnippets',
  MANDATOR = 'mandator',
  DASHBOARD = 'dashboard',
  DISTRIBUTOR = 'distributor',
  MOVIE = 'movie',
  LANGUAGES = 'languages',
  TRANSACTION = 'transaction',
  MANDATORS = 'mandators',
  TERMINAL_TYPES = 'terminalTypes',
  KIOSKS = 'kiosks',
  TERMINAL = 'terminal',
  KIOSK_LAYOUT_TYPES = 'kioskLayoutTypes',
  CINEMAS_WITH_AUDITORIUMS = 'cinemasWithAuditoriums',
  CINEMA = 'cinema',
  MANDATOR_FIRMS = 'mandatorFirms',
  CITIES = 'cities',
  PHONE_TYPES = 'phoneTypes',
  MANDATOR_DETAILS = 'mandatorDetails',
  TICKET_FEE = 'ticketFee',
  ONLINE_FEE = 'onlineFee',
}
