import { Injectable } from '@angular/core';
import { AbstractWsService } from '../../../core/services/AbstractWsService';
import { map, Observable } from 'rxjs';
import { ExportDocumentTypes, Filter, IPagination, Search, SortingTypes } from '@cinetixx/cinetixx-ui';
import { HttpUtils } from '../../../core/utils/HttpUtils';
import { ITableExport } from '../interfaces';
import { TableExportTypes } from '../enums';
import { HttpParams } from '@angular/common/http';
import { ReloadCache } from '../../../core/decorators/reload-cache.decorator';
import { Cache } from '../../../core/decorators/cache.decorator';
import { StoreCacheKeys } from '../../../core/enums';

@Injectable({
  providedIn: 'root'
})
export class ExportsService extends AbstractWsService {

  private readonly _basePath = '/exports';

  public getExportFileUrl(exportId: number, print?: boolean): Observable<string> {
    return this.apiService.get(
      `${ this._basePath }/export-file-url`,
      new HttpParams().append('id', exportId).append('print', print)
    );
  }

  @ReloadCache<void>(StoreCacheKeys.GetTableExports)
  public deleteExportsTable(id: number): Observable<void> {
    return this.apiService.delete(`${ this._basePath }/table?exportId=${ id }`);
  }

  @ReloadCache<void>(StoreCacheKeys.GetTableExports)
  public exportTable(
    properties: Record<string, string>,
    columns: Record<string, string>,
    exportType: TableExportTypes,
    outputTypes: ExportDocumentTypes,
    filename: string
  ): Observable<void> {
    return this.apiService.post(
      `${ this._basePath }/table`,
      {
        properties,
        columns,
        exportType,
        outputTypes,
        filename,
        operationId: this.prepareGuid()
      }
    );
  }

  public getPaginatedTablesExports(
    page: number,
    results: number,
    sortField: string,
    sortOrder: SortingTypes,
    search: Search = [],
    filter: Filter = []
  ): Observable<IPagination<ITableExport>> {
    return this.apiService.get(
      `${ this._basePath }/tables`,
      HttpUtils.prepareQueryableParams(page, results, sortField, sortOrder, search, filter)
    ).pipe(
      map(data => {
        data.results.forEach((x: ITableExport) => {
          x.fileName = x.fileName.split('.')[0];
          return x;
        });

        return data;
      })
    );
  }

  @Cache<IPagination<ITableExport>>(StoreCacheKeys.GetTableExports)
  public getLatestPaginatedTablesExports(
    page: number,
    results: number,
    sortField: string,
    sortOrder: SortingTypes,
    search: Search = [],
    filter: Filter = []
  ): Observable<IPagination<ITableExport>> {
    return this.apiService.get(
      `${ this._basePath }/tables`,
      HttpUtils.prepareQueryableParams(page, results, sortField, sortOrder, search, filter)
    ).pipe(
      map(data => {
        data.results = data.results.slice(0, 5).map((x: ITableExport) => {
          x.fileName = x.fileName.split('.')[0];
          return x;
        });

        return data;
      })
    );
  }
}
