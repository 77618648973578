import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberToEuro',
})
export class NumberToEuroPipe implements PipeTransform {

  public transform(
    value: number,
    withOptions = true,
    minimumFractionDigits: number = null,
    maximumFractionDigits: number = null
  ): string {
    return value.toEuro(withOptions, minimumFractionDigits, maximumFractionDigits);
  }
}
