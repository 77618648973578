import { inject, Pipe, PipeTransform } from '@angular/core';
import { ImageUrlService } from '@cinetixx/cinetixx-ui';


@Pipe({
  name: 'newsUrl'
})
export class NewsUrlPipe implements PipeTransform {

  private readonly _imageUrlService = inject(ImageUrlService);

  public transform(imageId: string, width: number, height: number): string {
    return this._imageUrlService.generateNewsUrl(imageId, width, height);
  }
}
