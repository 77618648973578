import { Pipe, PipeTransform} from '@angular/core';
import { DatePipe } from '@angular/common';
import { environment } from '../../../environments/environment';

@Pipe({
  name: 'timeFormat'
})
export class TimeFormatPipe extends DatePipe implements PipeTransform {

  public override transform(value: string, timezone: 'local' | 'auto' = 'auto'): any {
    if (!value) {
      return;
    }

    if (timezone === 'local' && value.endsWith('Z')) {
      value = value.slice(0, -1);
    }

    return super.transform(value, `${ environment.defaultTimeFormat }`) + ' Uhr'.trans('timeFormat.Hour');
  }
}
