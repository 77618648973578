import { inject, Injectable } from '@angular/core';
import { LanguageStoreService, NavigationService } from '@cinetixx/cinetixx-ui';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/core/services/ApiService';
import { TokenService } from 'src/app/core/services/TokenService';
import { IdentityService } from '../../../core/services/IdentityService';
import { IIdentity, ILogin, IResetPassword, ISetNewPassword } from '../interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly _basePath = '/employees';

  private readonly _apiService = inject(ApiService);
  private readonly _tokenService = inject(TokenService);
  private readonly _identityService = inject(IdentityService);
  private readonly _router = inject(Router);
  private readonly _languageStoreService = inject(LanguageStoreService);
  private readonly _navigationService = inject(NavigationService);

  public login(body: ILogin): Observable<IIdentity> {
    return this._apiService.post(`${ this._basePath }/login`, body);
  }

  public getStatus(): Observable<string> {
    return this._apiService.get(`${ this._basePath }/status`);
  }

  public refreshToken(): Observable<IIdentity> {
    const body = {
      refreshToken: this._tokenService.refreshToken
    };

    return this._apiService.post(`${ this._basePath }/refresh-token`, body);
  }

  revokeTokens(): void {
    const body = {refreshToken: this._tokenService.refreshToken};
    this._apiService.post(this._basePath + '/logout', body).subscribe();
  }

  public resetPassword(data: IResetPassword) {
    return this._apiService.post(`${this._basePath}/reset-password`, data);
  }

  public setPassword(data: ISetNewPassword) {
    return this._apiService.post(`${this._basePath}/set-password`, data);
  }

  public purgeAuth(withRedirect = false) {
    if (this._identityService.identity) {
      this.revokeTokens();
    }

    this._identityService.destroyIdentity();

    if (withRedirect) {
      this._router.navigateByUrl('/auth/login'.langUrl());
    }
  }

  public logout(): void {
    const backPath = location.pathname.replace(`/${ this._languageStoreService.language.token }`, '');
    this._identityService.destroyIdentity();
    this._navigationService.navigateByUrl(`/auth/login?back=${ backPath }`);
  }
}
