import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { NoLangGuard } from './core/guards/no-lang.guard';
import { AppComponent } from './app.component';
import { LanguagesSnippetsResolver } from './language/core/resolvers/languages-snippets.resolver';
import { ILanguage } from './language/core/interfaces/ILanguage';
import { ResolverKeys } from './core/enums/ResolverKeys';

const userLang = JSON.parse(localStorage.getItem('user-lang')) as ILanguage;
const routes: Routes = [
  {
    path: '',
    redirectTo: `${ userLang?.token ?? environment.fallbackLanguage }/dashboard`,
    pathMatch: 'full',
  },
  {
    path: ':language',
    loadChildren: () => import('./_main/main.module').then(m => m.MainModule),
    resolve: {
      [ResolverKeys.LANGUAGES_SNIPPETS]: LanguagesSnippetsResolver,
    },
  },
  {
    path: '**',
    canActivate: [NoLangGuard],
    component: AppComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    bindToComponentInputs: true,
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
