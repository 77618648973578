import {
  Directive, inject,
  Input,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { PermissionService } from '../services/PermissionService';
import { Actions } from '../enums';

@Directive({
  selector: '[appShowForRole]'
})
export class ShowForRoleDirective {

  @Input() set appShowForRole(action: Actions) {
    if (this._permissionsService.hasAccess(action) && !this._isCreated) {
      this._isCreated = true;
      this._viewContainer.createEmbeddedView(this._templateRef);
    } else {
      this._isCreated = false;
      this._viewContainer.clear();
    }
  }

  private _isCreated = false;

  private readonly _templateRef = inject(TemplateRef);
  private readonly _viewContainer = inject(ViewContainerRef);
  private readonly _permissionsService = inject(PermissionService);
}
