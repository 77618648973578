<router-outlet (activate)="isLoaderVisible = false"></router-outlet>

<div class="loader-container" *ngIf="isLoaderVisible">
  <img loading="lazy" src="/assets/images/logos/cinetixx-logo.svg" alt="cinetixx" />
  <div class="loader">
    <div class="loader-bar"></div>
  </div>
</div>

<cx-new-version-modal #newVersionModalComponent *ngIf="isNewVersionModalVisible"></cx-new-version-modal>
