import {
  Directive, inject,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { IdentityService } from '../services/IdentityService';

@Directive({
  selector: '[appShowAuthed]'
})
export class ShowAuthedDirective implements OnInit {

  @Input() set appShowAuthed(condition: boolean) {
    this.condition = condition;
  }

  public condition: boolean;
  public isCreated = false;

  private readonly _templateRef = inject(TemplateRef);
  private readonly _identityService = inject(IdentityService);
  private readonly _viewContainer = inject(ViewContainerRef);

  public ngOnInit(): void {
    if (
      this._identityService.isAuthenticated && this.condition && !this.isCreated ||
      !this._identityService.isAuthenticated && !this.condition && !this.isCreated
    ) {
      this.isCreated = true;
      this._viewContainer.createEmbeddedView(this._templateRef);
    } else if (
      this._identityService.isAuthenticated && !this.condition ||
      !this._identityService.isAuthenticated && this.condition
    ) {
      this._viewContainer.clear();
      this.isCreated = false;
    }
  }
}
