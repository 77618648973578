import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import { LanguageStoreService } from '../../language/core/services/LanguageStoreService';
import { environment } from '../../../environments/environment';


export const NoLangGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  languageStore = inject(LanguageStoreService)
): boolean => {
  location.href = `/${ languageStore.language?.token ?? environment.fallbackLanguage }`;

  return true;
};
