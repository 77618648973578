import { inject, Injectable } from '@angular/core';
import { IdentityService } from './IdentityService';
import { Roles, Actions } from '../enums';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  private readonly _standardUserRules = [];
  private readonly _managerRules = [
    ...this._standardUserRules,
    Actions.ADD_SCANNER,
  ];
  private readonly _cinemaOwnerRules = [
    ...this._managerRules,
    Actions.PLANNING,
    Actions.ACTIVATION_ORDERS,
    Actions.INVOICES,
    Actions.FFA,
    Actions.ADD_EMPLOYEES,
    Actions.EDIT_EMPLOYEES
  ];
  private readonly _adminRules = [
    ...this._cinemaOwnerRules,
    Actions.EDIT_ACCOUNT_DETAILS,
    Actions.ADD_TSE
  ];
  private readonly _superAdminRules = [
    ...this._adminRules,
    Actions.CONTROLLING,
    Actions.AWS_DASHBOARD,
    Actions.BUTTON_RESEND_EMAILS
  ];
  private readonly _rules = {
    [Roles.MANAGER]: this._managerRules,
    [Roles.CINEMA_OWNER]: this._cinemaOwnerRules,
    [Roles.STANDARD_USER]: this._standardUserRules,
    [Roles.ADMINISTRATOR]: this._adminRules,
    [Roles.SUPER_ADMINISTRATOR]: this._superAdminRules,
  };

  private readonly _identityService = inject(IdentityService);

  public get rules(): Actions[] {
    return this._rules[this._identityService.identity?.role] ?? [];
  }

  public hasAccess(action: Actions): boolean {
    return this.rules.includes(action);
  }
}
