import { inject, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UrlTrailerService } from '../services/UrlTrailerService';

@Pipe({
  name: 'urlTrailer'
})
export class UrlTrailerPipe implements PipeTransform {

  private readonly _uriTrailerService = inject(UrlTrailerService);
  private readonly _sanitizer = inject(DomSanitizer);

  public transform(url: string, autoplay: boolean): SafeResourceUrl {
    const embedUrl = `${ this._uriTrailerService.setTrailerUri(url, autoplay) }`;
    return this._sanitizer.bypassSecurityTrustResourceUrl(embedUrl);
  }

}
