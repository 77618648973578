import { Pipe, PipeTransform } from '@angular/core';
import { IDictionaryValue } from '../interfaces';
import { IIdAndName } from '@cinetixx/cinetixx-ui';

@Pipe({
  name: 'dictionaryValue'
})
export class DictionaryValuePipe implements PipeTransform {

  public transform(id: number | string, dictionary: IDictionaryValue[] | IIdAndName[]): string {
    return dictionary.find(dct => dct.id.toString() === id.toString())?.name;
  }
}
