import { Apps } from '../app/core/enums';

export const environment = {
  production: false,
  isSW: false,
  app: Apps.Admin,
  brand: 'CX',
  apiUrl: 'https://dev.api.backend.cinetixx.com',
  cloudFrontUrl: 'https://images.cinetixx.com',
  managerUrl: 'https://dev.manager.cinetixx.com',
  appUrl: 'https://dev.admin.cinetixx.com',
  fallbackLanguage: 'de',
  movieDataBaseResultsPage: 50,
  mandatorToken: 'CCAG',
  servicesDateFormat: 'YYYY-MM-DD',
  servicesDateTimeFormat: 'YYYY-MM-DD HH:mm',
  defaultDateFormat: 'dd.MM.yyyy',
  defaultTimeFormat: 'HH:mm',
  version: 'dev-5080',
  wsUrl: 'https://dev.api.backend.cinetixx.com/signalr',
  dsn: 'https://61d1bc5dc849d44076854c69dc418e6c@o4508816761225216.ingest.de.sentry.io/4508817104306256',
  versionPrefix: 'dev',
  trailersUrl: 'https://trailers.cinetixx.com/',
  releaseNotesUrl: 'https://cinetixx.de/assets/pdf/',
  postersLink: 'https://images.cinetixx.com/posters',
};
