import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UrlTrailerService {

  public setTrailerUri(url, autoplay): string {
    const trailerUri = this.matchUri(url);
    switch (trailerUri.type) {
      case 'youtube':
        return autoplay ? `//www.youtube.com/embed/${ trailerUri.id }?autoplay=1` : `//www.youtube.com/embed/${ trailerUri.id }`;

      case  'vimeo' :
        return autoplay ? `//player.vimeo.com/video/${ trailerUri.id }?autoplay=1` : `//player.vimeo.com/video/${ trailerUri.id }`;

      default : return '';
    }
  }

  public urlVerification = (url): boolean => url !== null && url !== '' && url !== undefined ? this.matchUri(url).type !== '' : false;

  public matchUri(url): {type: string, id: string} {

    let type = '';
    url.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/);

    if (RegExp.$3.indexOf('youtu') > -1) {
      type = 'youtube';
    } else if (RegExp.$3.indexOf('vimeo') > -1) {
      type = 'vimeo';
    }

    return {
      type: type,
      id: RegExp.$6
    };
  }
}
